import React from "react";
// import { Link } from "gatsby";
import { useLocation } from "@reach/router";

import "../styles/index.css";
import * as layoutStyles from "./layout.module.css";

import Header from "./header";
import Footer from "./footer";

// function darkToggle() {
//   var element = document.body;
//   element.classList.toggle("dark-mode");
// }

const Layout = ({ children }) => {
  const location = useLocation();
  const isHomepage = location.pathname === "/";

  return (
    <div className={layoutStyles.container}>
      <div className={layoutStyles.content}>
        <Header />
        {children}
        <br />
        {/* <hr style={{ background: "black" }} /> */}
        {/* <h3>
          <Link to="/artists">Artists List</Link>
        </h3> */}
      </div>
      {/* <button onClick={darkToggle}>Toggle dark mode</button> */}
      {/* <Footer /> */}
      {!isHomepage && <Footer />}
    </div>
  );
};

export default Layout;
