import React from "react";
// import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Head = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <>
      <title>{`${title} | ${data.site.siteMetadata.title}`}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content="page of artnow.world" />
      <meta
        name="keywords"
        content="art, react, gatsby, san francisco, bay area, berkeley, art project, online gallery"
      />
      <meta charSet="utf-8" />
    </>
  );
};

export default Head;
