import React from "react";
// import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";

import * as footerStyles from "./footer.module.css";

const Footer = () => {
  // const data = useStaticQuery(graphql`
  //   {
  //     site {
  //       siteMetadata {
  //         title
  //         author
  //         description
  //         year
  //       }
  //     }
  //   }
  // `);

  return (
    <footer className={footerStyles.footer}>
      <hr style={{ background: "black" }} />
      {/* <p>Website by {data.site.siteMetadata.title}, ©2021</p> */}
      {/* <p>{data.site.siteMetadata.year}</p> */}
      {/* <p style={{ fontSize: "1em", fontStyle: "italic" }}>
        <Link to="/">artnow.world</Link>
      </p> */}
      <p style={{ fontSize: "1em", fontWeight: "bold" }}>
        <Link to="/" style={{ textDecoration: "none" }}>
          artnow.world
        </Link>
      </p>
    </footer>
  );
};

export default Footer;
